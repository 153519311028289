import React from 'react';

import { FacebookShareButton, TwitterShareButton, LinkedinShareButton } from 'react-share';

import Card from '../../components/Card';
import Layout from '../../components/Layout';
import TestimonialSlider from '../../components/TestimonialSlider';
import Quote from '../../components/Quote';

import raphaelCardona from '../../assets/images/raphael-cardona.jpg';
import twitter from '../../assets/images/bx_bxl-twitter.svg';
import facebook from '../../assets/images/bx_bxl-facebook.svg';
import linkedin from '../../assets/images/bx_bxl-linkedin.svg';
import markHilton from '../../assets/images/mark-hilton.jpg';
import twoBirds from '../../assets/images/bird&bird--full--cyan.jpg';
import planner from '../../assets/images/01-2birds-case-study--project-plan.jpg';
import allocate from '../../assets/images/03-2birds-case-study--timeline.jpg';
import autopilot from '../../assets/images/02-2birds-case-study--triggers.jpg';
import buildings from '../../assets/images/buildings.jpg';
import helgaButcher from '../../assets/images/helga-butcher-article.jpg';
import fibShot from '../../assets/images/fib-shot-3.jpg';
import fibonacci from '../../assets/images/fibonacci-full.svg';

const articleRemote = ({ path }) => (
  <Layout
    pageName="default-article"
    path={path}
    metatitle="Transforming multi-jurisdictional litigation with Bird & Bird"
    image="/metaimages/homepage.jpg"
    description="In 2019, Bird & Bird, one of the world's leading international intellectual property firms, wanted a better and more efficient way to manage large-scale..."
  >
    <div className="page">
      <div className="banner -resources">
        <div className="chip">NEWS</div>
        <h1>
          Transforming multi-jurisdictional
          <br />
          litigation with Bird & Bird
        </h1>
      </div>
      <div className="article-container">
        <img src={buildings} className="image" alt="" />
        <div className="information">
          <div className="item">
            <h2 className="title">London</h2>
            <div className="subtitle">Headquarters</div>
          </div>
          <div className="item">
            <h2 className="title">1,300 lawyers</h2>
            <div className="subtitle">Number of lawyers</div>
          </div>
          <div className="item">
            <h2 className="title">29 offices</h2>
            <div className="subtitle">Locations</div>
          </div>
        </div>
        <div className="content">
          <TestimonialSlider>
            <Quote
              author="Mark Hilton"
              position="Partner, Bird & Bird"
              avatar={markHilton}
              logo={twoBirds}
              content="Fibonacci has transformed how I manage complex multi-jurisdictional
                litigation. The platform provides us with unrivalled visibility across our teams and
                projects across all countries involved. With routine processes automated, and with
                real-time reports delivered to my clients at the click of a button, I’m better able
                to focus on the substantive issues at hand."
            />
          </TestimonialSlider>
          <p className="paragraph">
            <b>
              Issue
            </b>
          </p>
          <p className="paragraph">
            In 2019, Bird & Bird, one of the world&apos;s leading international intellectual
            property firms, wanted a better and more efficient way to manage large-scale
            multi-jurisdictional patent litigation. Such complex, truly global mandates can involve
            multiple counterparties, multiple patents, voluminous documentation and - where local
            counsel is required - multiple law firms. An already difficult scenario was made all
            the more complex when you consider the number of stakeholders involved, all with
            different needs and requirements.
          </p>
          <p className="paragraph">
            The status quo required the management of many excel spreadsheets, a multiplicity of
            software solutions, and being able to digest ever increasing volumes of email traffic.
            This resulted in a scenario where lawyers spent too much time inputting and collating
            data across multiple sources, coordinating effort and project managing the ‘sum of the
            whole’, which competed against the time required to focus on the substantive legal
            issues on hand.  Not only was it very difficult to efficiently project manage these
            complex matters, but trying to provide the client with certainty and visibility over
            work performed became a huge undertaking.
          </p>
          <p className="paragraph"><b>Solution</b></p>
          <p className="paragraph">
            Looking for a more efficient and innovative way to manage and deliver complex
            litigation, and to address the hurdles detailed above, Bird & Bird turned to
            Fibonacci.
          </p>
          <p className="paragraph">
            The implementation brief for Bird & Bird was clear: Fibonacci needed to ensure it could
            provide Bird & Bird with oversight over (i) all the pending and current matters across
            each jurisdiction involved in the litigation; (ii) each critical event - including
            deadlines - across every matter; (iii) key milestones that could change Bird & Bird’
            litigation strategy.
          </p>
          <p className="paragraph">
            <i>Fibonacci addressed each requirement via the following features described below:</i>
          </p>
          <img className="screenshot" src={planner} alt="" />
          <p className="paragraph"><b>Blueprint</b></p>
          <p className="paragraph">
            Fibonacci&apos;s blueprint feature empowers each stakeholder involved in the litigation
            to build structured, workstream driven project plans that provide certainty, visibility
            and clarity. Blueprint provided the means for Bird & Bird to say goodbye to maintaining
            and updating Excel spreadsheets and their reliance on legacy technology by allowing
            complex litigation matters to be quickly and efficiently deconstructed into a simple to
            execute the project plan, which detailed all the essential workstreams - composed of
            milestones and tasks - that are required to drive the litigation forward through to
            successful completion.
          </p>
          <p className="paragraph">
            To eradicate voluminous email communication, Fibonacci provided a means for contextual
            discussion to take place: each conversation occurring within Fibonacci related to either
            the project plan, a milestone, or a key task.  Less time is spent digging up emails or
            chasing responses as the entire project team had visibility over all the moving parts.
            This ensured resources were efficient and spent time delivering on their expertise,
            and spent time working the plan, rather than continuously planning the project.
          </p>
          <img className="screenshot" src={allocate} alt="" />
          <p className="paragraph"><b>Timeline</b></p>
          <p className="paragraph">
            As litigation projects gathered momentum, Bird & Bird were finding it difficult to align
            the competing projective objectives of their multi-jurisdictional project teams. A lot
            of time and effort was being spent in coordinating the project and the key events
            related to each matter, often requiring accessing and reviewing multiple calendars and
            Excel spreadsheets.
          </p>
          <p className="paragraph">
            Fibonacci’s timeline feature provided the means for all project stakeholders to
            efficiently and effectively access and view an events timeline, identifying event
            priority, estimated time and key assignees in the one place. It provides a source of
            information that assists Bird & Bird multi-jurisdictional project teams to align project
            objectives, identify possible clashes, manage resourcing and ensure visibility of all
            critical events and actions. Clients are also empowered to access a real time events
            timeline and can engage and collaborate with Bird & Bird as to project delivery.
          </p>
          <img className="screenshot" src={autopilot} alt="" />
          <p className="paragraph"><b>Triggers</b></p>
          <p className="paragraph">
            During complex patent litigation matters, it can be difficult to track all of the
            happenings whilst a project is evolving, some of these events will represent important
            milestones, and have consequences of their own. Traditionally, these events have been
            tracked manually by lawyers, using legacy technology, who have a tacit understanding of
            what to look for and what action to take when such events occur. This means that any
            information and knowledge related to that event is kept siloed. Bird & Bird needed a
            better way to manage these key events, ensuring that key tacit knowledge was shared
            amongst internal project members to track their completion, ensuring that key
            stakeholders are kept informed as and when events occur and what action needs to happen.

          </p>
          <p className="paragraph">
            Fibonacci’s trigger feature ensures that when an important event occurs, tacit
            knowledge related to that event is transferred to the right people and that they are
            informed to make the right decision as to what action needs to occur.
          </p>
          <p className="paragraph"><b>Thoughts</b></p>
          <p className="paragraph">
            Fibonacci brings everything together under the one roof in one platform: the constant
            itch to switch between inundated and outdated software solutions, and filtering
            overburdened email inboxes has finally been displaced. Fibonacci has not only made
            valuable information easier to access, but made it more insightful. Key decisions can be
            made quicker, and each stakeholder is empowered to always remain on the front-foot and
            make valuable proactive decisions.
          </p>
          <p className="paragraph">
            <i>
              Interested to see how we can supercharge your practice?
            </i>
          </p>
          <div className="author-details">
            <div className="label _bold">Author</div>
            <div className="base-chip -large author">
              <img className="avatar -no-border" src={raphaelCardona} alt="" />
              <div className="text">
                <div className="title">
                  <span className="name">Raphael Cardona</span>
                </div>
                <div className="subtitle">Head of Product, Fibonacci</div>
                <img className="logo -custom" src={fibonacci} alt="" />
              </div>
            </div>
            <a
              className="base-button -inline -message message-link"
              href="mailto:raph@fibonacci.legal"
            >
              Message Raphael
            </a>
          </div>
        </div>
        <div className="social-container social">
          <div className="header">Like this article? Spread the word</div>
          <div className="logos">
            <LinkedinShareButton
              title="Transforming multi-jurisdictional litigation with Bird & Bird"
              url="https://fibonacci.legal/blog/transforming-multi-jurisdictional-litigation-with-bird-and-bird"
            >
              <div className="social-button">
                <img src={linkedin} alt="" />
              </div>
            </LinkedinShareButton>
            <TwitterShareButton
              title="Transforming multi-jurisdictional litigation with Bird & Bird"
              url="https://fibonacci.legal/blog/transforming-multi-jurisdictional-litigation-with-bird-and-bird"
            >
              <div className="social-button">
                <img src={twitter} alt="" />
              </div>
            </TwitterShareButton>
            <FacebookShareButton
              quote="Transforming multi-jurisdictional litigation with Bird & Bird"
              url="https://fibonacci.legal/blog/transforming-multi-jurisdictional-litigation-with-bird-and-bird"
            >
              <div className="social-button">
                <img src={facebook} alt="" />
              </div>
            </FacebookShareButton>
          </div>
        </div>
        <h2 className="_bold divider">Keep reading</h2>
        <div className="card-gallery gallery">
          <Card
            image={helgaButcher}
            imageStyle={{ objectPosition: 'top' }}
            title="From the Legal Project Manager’s mouth: Helga Butcher’s LPM journey at Barclays"
            to="/blog/from-the-legal-project-managers-mouth"
            author="Luke Edwardes-Evans"
          />
          <Card
            image={fibShot}
            title="Fibonacci: the Golden Standard for Legal Project Management"
            to="/blog/fibonacci-the-golden-standard-for-legal-project-management"
            author="Alexander Trend"
            imageStyle={{ transform: 'scale(1.4)' }}
          />
        </div>
      </div>

      <div className="topbg" />
      <div className="bottombg" />
    </div>
  </Layout>
);

export default articleRemote;
